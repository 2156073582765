<template>
  <div class="user-card-content">
    <CardComponent ref="card" variant="tertiary">
      <template #header>
        {{ $tc('app.user', 2) }}
        <ul class="actions top-right">
          <li>
            <a role="button" @click="fetch(1)">
              <span class="material-symbols-outlined"> refresh </span>
            </a>
          </li>
          <li>
            <a role="button" @click="$refs['create-user'].show()">
              <span class="material-symbols-outlined"> add </span>
            </a>
          </li>
        </ul>
      </template>
      <TableComponent :busy="loading" :fields="fields" :items="users">
        <template #cell(pivot.role)="slotProps">
          {{ $tc(`app.${slotProps.data}`, 1) }}
        </template>
        <template #cell(pivot.is_agent)="slotProps">
          <span v-if="slotProps.data" class="material-symbols-outlined"> done </span>
        </template>
        <template #cell(departments)="slotProps">
          <div>
            <BadgeComponent v-for="department in slotProps.data" :key="department.id" variant="light" class="m-1">
              {{ department.name }}
            </BadgeComponent>

            <b-tooltip v-if="slotProps.data.length > 2" :title="departmentsTooltip(slotProps.data)">
              <BadgeComponent variant="outline">+{{ slotProps.data.length - 2 }}</BadgeComponent>
            </b-tooltip>
            <b-tooltip v-else>
              <BadgeComponent variant="outline">0</BadgeComponent>
            </b-tooltip>
          </div>
        </template>
        <template #cell(actions)="slotProps">
          <Dropdown>
            <template v-slot:text>
              <span class="material-symbols-outlined title"> more_vert </span>
            </template>
            <template #items>
              <a v-modal="'create-user'" class="dropdown-item" href="javascript:void(0)" @click="editUser(slotProps.item)">
                {{ $t('generic-str.edit') }}
              </a>
              <button class="dropdown-item" @click="destroy(slotProps.item.id)">
                {{ $t('generic-str.remove') }}
              </button>
            </template>
          </Dropdown>
        </template>
      </TableComponent>
      <PaginationComponent v-model="currentPage" :totalPages="totalPages" @change="fetch" />
    </CardComponent>
    <Modal ref="edit-user" title="Editar Usuário" @confirm="updateUser">
      <form v-if="editingUser">
        <FormGroup label="Email">
          <FormInput v-model="editingUser.email" readonly />
        </FormGroup>
        <FormGroup label="Nome Completo" required>
          <FormInput v-model="editingUser.name" />
        </FormGroup>
        <FormGroup label="Departamento" required>
          <FormSelect v-model="editingUser.departments" :options="departments" text-field="name" value-field="id" multiple />
        </FormGroup>
        <FormGroup v-if="editingUser.role !== 'owner'" label="Função" required>
          <FormSelect v-model="editingUser.role" :options="roles" required />
          <Alert>
            <p v-if="editingUser.role === 'user'">
              Ver relatórios, interagir com os canais. (Chat)
              <!-- (Omni Business) -->
              Atendimento.
            </p>
            <p v-else-if="editingUser.role === 'billing'">
              Permissão a todos os relatórios e área financeira da plataforma.
            </p>
            <p v-else-if="editingUser.role === 'developer'">
              Permissão de configurar webhook, widget (Yuper Chat), analise de relatórios da plataforma.
            </p>
            <p v-else-if="editingUser.role === 'admin'">
              Permissão total da plataforma, com exceção da contratação de produtos ou pacotes de serviço.
            </p>
            <p v-else-if="editingUser.role === 'sales'">Relatórios Gestão.</p>
          </Alert>
        </FormGroup>
        <FormCheckbox id="checkbox" ref="editCheckbox" v-model="editingUser.is_agent" @input="checkAgents('checkbox', true)">Agente </FormCheckbox>
        <FormGroup v-if="editingUser.is_agent" label="Máximo de atendimentos" required>
          <FormInput v-model="editingUser.max_calls_at_time" />
        </FormGroup>
      </form>
    </Modal>
    <SidebarComponent ref="edit-user" backdrop title="Editar Usuário">
      <FormComponent v-if="editingUser" ref="update-user" class="register" @submit="updateUser">
        <InputComponent v-model="editingUser.email" :label="$t('app.email')" required type="email" />
        <SelectComponent
          v-model="editingUser.departments"
          :label="$tc('app.department', 1)"
          :options="departments"
          text-field="name"
          value-field="id"
          multiple
          @change="console.log('Selected Departments:', editingUser.department)"
        />
        <SelectComponent v-model="editingUser.role" :label="$tc('app.role', 1)" :options="roles" required />
        <Alert v-if="editingUser.role">
          <p v-if="editingUser.role === 'user'">
            Ver relatórios, interagir com os canais. (Chat)
            <!-- (Omni Business) -->
            Atendimento.
          </p>
          <p v-else-if="editingUser.role === 'billing'">
            Permissão a todos os relatórios e área financeira da plataforma.
          </p>
          <p v-else-if="editingUser.role === 'developer'">
            Permissão de configurar webhook, widget (Yuper Chat), analise de relatórios da plataforma.
          </p>
          <p v-else-if="editingUser.role === 'admin'">
            Permissão total da plataforma, com exceção da contratação de produtos ou pacotes de serviço.
          </p>
          <p v-else-if="editingUser.role === 'sales'">Relatórios Gestão.</p>
        </Alert>
        <FormCheckbox id="checkbox1" v-model="editingUser.is_agent" :checked="editingUser.is_agent" @input="checkAgents('checkbox1', true)">
          Agente
        </FormCheckbox>
        <InputComponent v-if="editingUser.is_agent" v-model="editingUser.max_calls_at_time" :rules="['max-numeric:40', 'integer']" label="Máximo de atendimentos" required type="tel" />
      </FormComponent>
      <template #footer>
        <div class="d-flex justify-content-between">
          <ButtonComponent variant="secondary" @click="$refs['edit-user'].hide()">
            {{ $t('app.cancel') }}
          </ButtonComponent>
          <ButtonComponent :loading="updating" @click="$refs['update-user'].submit()">
            {{ $t('app.update') }}
          </ButtonComponent>
        </div>
      </template>
    </SidebarComponent>
    <SidebarComponent ref="create-user" backdrop title="Criar Usuário">
      <FormComponent ref="store-user" class="register" @submit="storeUser">
        <InputComponent v-model="form.email" :label="$t('app.email')" required type="email" />
        <SelectComponent v-model="form.departments" :label="$tc('app.department', 2)" :options="departments" text-field="name" value-field="id" multiple />
        <SelectComponent v-model="form.role" :label="$tc('app.role', 1)" :options="roles" required />
        <Alert v-if="form.role">
          <p v-if="form.role === 'user'">
            Ver relatórios, interagir com os canais. (Chat)
            <!-- (Omni Business) -->
            Atendimento.
          </p>
          <p v-else-if="form.role === 'billing'">
            Permissão a todos os relatórios e área financeira da plataforma.
          </p>
          <p v-else-if="form.role === 'developer'">
            Permissão de configurar webhook, widget (Yuper Chat), analise de relatórios da plataforma.
          </p>
          <p v-else-if="form.role === 'admin'">
            Permissão total da plataforma, com exceção da contratação de produtos ou pacotes de serviço.
          </p>
          <p v-else-if="form.role === 'sales'">Relatórios Gestão.</p>
        </Alert>
        <FormCheckbox id="checkbox2" v-model="form.is_agent" :checked="form.is_agent" @input="checkAgents('checkbox2', false)">
          Agente
        </FormCheckbox>
        <InputComponent v-if="form.is_agent" v-model="form.max_calls_at_time" :rules="['max-numeric:40', 'integer']" label="Máximo de atendimentos" required type="tel" />
      </FormComponent>
      <template #footer>
        <div class="d-flex justify-content-between">
          <ButtonComponent variant="secondary" @click="hideCreateUserForm">
            {{ $t('app.cancel') }}
          </ButtonComponent>
          <ButtonComponent :loading="updating" @click="$refs['store-user'].submit()">
            {{ $t('app.create') }}
          </ButtonComponent>
        </div>
      </template>
    </SidebarComponent>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import Modal from '@/components/rebranding/modal/Modal.vue';
import FormGroup from '@/components/form/FormGroup.vue';
import FormInput from '@/components/form/FormInput.vue';
import FormSelect from '@/components/rebranding/form/FormSelect.vue';
import FormCheckbox from '@/components/rebranding/form/FormCheckbox.vue';
import PaginationComponent from '@/components/PaginationComponent.vue';
import Alert from '@/components/rebranding/alert/Alert.vue';
import Dropdown from '@/components/Dropdown.vue';
import axios from '@/plugins/axios';
import CardComponent from '@/components/CardComponent.vue';
import TableComponent from '@/components/TableComponent.vue';
import SidebarComponent from '@/components/SidebarComponent.vue';
import InputComponent from '@/components/form/InputComponent.vue';
import SelectComponent from '@/components/form/SelectComponent.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';
import FormComponent from '@/components/form/FormComponent.vue';
import BadgeComponent from '../ui/BadgeComponent.vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';

const webApi = axios();

export default {
  name: 'UsersCard',
  components: {
    FormComponent,
    ButtonComponent,
    SelectComponent,
    InputComponent,
    SidebarComponent,
    TableComponent,
    CardComponent,
    Dropdown,
    Modal,
    FormGroup,
    FormInput,
    FormSelect,
    FormCheckbox,
    Alert,
    PaginationComponent,
    BadgeComponent,
  },
  data() {
    return {
      loading: true,
      updating: false,
      fields: [
        {
          key: 'name',
        },
        {
          key: 'email',
        },
        {
          key: 'pivot.role',
          label: this.$tc('app.role', 1),
        },
        {
          key: 'departments',
          label: this.$tc('app.department', 1),
        },
        {
          key: 'pivot.is_agent',
          label: this.$tc('app.agent', 1),
        },
        'actions',
      ],
      tableFields: [
        {
          key: 'name',
        },
        {
          key: 'email',
        },
        {
          key: 'pivot.role',
          label: this.$tc('app.role', 1),
          localize: true,
        },
        {
          key: 'departments.0.name',
          label: this.$tc('app.department', 1),
        },
        {
          key: 'pivot.is_agent',
          label: this.$tc('app.agent', 1),
          type: Boolean,
        },
      ],
      departments: [],
      totalPages: 1,
      fullscreen: false,
      q: '',
      page: 1,
      pages: 1,
      users: [],
      editingUser: null,
      form: {},
      message: '',
      totalAgents: 19,
      currentPage: 1,
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    roles() {
      const roles = [
        { value: 'user', text: 'Analista' },
        { value: 'billing', text: 'Financeiro(a)' },
        { value: 'developer', text: 'Desenvolvedor(a)' },
        { value: 'admin', text: 'Gestor(a)' },
      ];

      if (this.$store.state.account.whitelabel_id == null) {
        roles.push({ value: 'sales', text: 'Executivo(a) de Vendas' });
      }
      return roles;
    },
  },
  created() {
    this.fetch();
    this.fetchDepartments();
  },
  mounted() {
    this.$root.$on('UsersCardReload', () => {
      this.fetch();
    });
  },
  methods: {
    agentsAmount() {
      webApi.get('/accounts/agentsAmount').then((response) => {
        this.totalAgents = response.data;
      });
    },

    showModalInfo(item) {
      console.info('$emit:UsersModal', item);
      this.$root.$emit('UsersModal', item);
      this.$refs['users-modal'].showHandler('users-modal', item);
    },
    fetch(page = 1) {
      this.loading = true;
      this.currentPage = page;
      this.$store.dispatch('fetchUsers', { page: this.currentPage, q: this.q }).then((response) => {
        this.users = response.data;
        this.totalPages = response.last_page;
        this.loading = false;
      });
      this.agentsAmount();
    },
    fetchDepartments() {
      this.$store.dispatch('fetchDepartments').then((response) => {
        this.departments = response.data;
        console.log('Departamentos:', this.departments);
      });
    },
    checkAgents(checkbox, isEditing) {
      const checkBox = document.getElementById(checkbox);
      if (this.totalAgents > 1 && checkBox.checked) {
        Swal.fire({
          title: this.$t('app.warning'),
          text: this.$t('users.charge'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('generic-str.confirm'),
          cancelButtonText: this.$t('app.cancel'),
        }).then((result) => {
          console.log(result.isConfirmed);
          isEditing ? (this.editingUser.is_agent = result.isConfirmed) : (this.form.is_agent = result.isConfirmed);
          // checkBox.checked = result.isConfirmed;
        });
      }
    },
    updateUser() {
      this.updating = true;
      this.$store
        .dispatch('updateUser', this.editingUser)
        .then((response) => {
          this.$refs['edit-user'].hide();
          this.$toast.show({
            title: this.$t('app.success'),
            content: this.$t('user.updated'),
            type: 'success',
          });
          this.fetch();
        })
        .catch((error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.response.data.code}`,
            content: error.response.data.message,
            type: 'danger',
          });
        })
        .finally(() => {
          this.updating = false;
        });
    },
    editUser(user) {
      this.editingUser = {
        id: user.id,
        email: user.email,
        departments: user.departments.map((d) => d.id),
        is_agent: user.pivot.is_agent,
        max_calls_at_time: user.pivot.max_calls_at_time,
        name: user.name,
        role: user.pivot.role,
      };
      this.$refs['edit-user'].show();
    },
    hideCreateUserForm() {
      this.$refs['create-user'].hide();
      this.form = {};
    },
    storeUser() {
      this.updating = true;
      this.$store
        .dispatch('storeUser', this.form)
        .then((response) => {
          this.hideCreateUserForm();
          this.$toast.show({
            title: this.$t('app.success'),
            content: this.$t('user.created'),
            type: 'success',
          });
          this.fetch();
        })
        .catch((error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.response.data.code}`,
            content: error.response.data.message,
            type: 'danger',
          });
        })
        .finally(() => {
          this.updating = false;
        });
    },
    search(q) {
      // console.log('What arrives: ', q);
      this.q = q;
      this.fetch(1);
    },
    departmentsTooltip(departments) {
      return departments.map((d) => d.name).join(', ');
    },
    /* search() {
      console.log('What arrives: ', this.qSearch);
      this.$emit('search', this.qSearch);
    }, */
    destroy(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('user.destroy'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
        cancelButtonText: this.$t('app.cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          this.$store.dispatch('destroyUser', id).then((response) => {
            this.fetch();
          });
        }
      });
    },
    changePage(page) {
      /* this.page = page;
      this.$emit('change-page', this.page); */
      this.fetch(page);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .pagination_row {
  display: none !important;
}

::v-deep {
  .user-card-content .modal {
    overflow-y: auto !important;
  }

  .table-responsive {
    overflow: visible;
  }
}
</style>
