<template>
  <div>
    <CardComponent
      ref="card"
      :actions="true"
      :loading="loading"
      :searchable="true"
      :usersDep="true"
      block
      variant="tertiary"
      @messageChanged="search"
      @reload="fetch"
    >
      <template #header>
        {{ $tc("app.department", 2) }}
        <ul class="actions top-right">
          <li>
            <a href="javascript:void(0)" @click="showFilter">
              <span class="material-symbols-outlined filter-department">
                filter_list
              </span>
            </a>
          </li>
          <li>
            <a role="button" @click="fetchDepartments">
              <span class="material-symbols-outlined"> refresh </span>
            </a>
          </li>
          <li>
            <a role="button" v-modal="{ target: 'department-modal' }">
              <span class="material-symbols-outlined"> add </span>
            </a>
          </li>
          <li></li>
        </ul>
      </template>
      <TableComponent :busy="loading" :fields="fields" :items="departments">
        <template #cell(active)="slotProps">
          <span v-if="slotProps.data" class="material-symbols-outlined"> done </span>
        </template>
        <template #cell(actions)="slotProps">
          <dropdown>
            <template v-slot:text>
              <span class="material-symbols-outlined title"> more_vert </span>
            </template>
            <template #items>
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                @click="showModalInfo(slotProps.item)"
              >
                <!-- v-modal="{ target: 'department-modal', data: item } -->
                {{ $t("generic-str.edit") }}
              </a>
              <button class="dropdown-item" @click="destroy(slotProps.item.id)">
                {{ $t("generic-str.remove") }}
              </button>
            </template>
          </dropdown>
        </template>
      </TableComponent>
      <department-modal
        id="department-modal"
        ref="department-modal"
        @created="fetch"
        @updated="fetch"
      ></department-modal>
      <div class="card-footer">
        <pagination :lastPage="pages" @change="changePage" />
      </div>
    </CardComponent>
    <SidebarComponent
      ref="filter"
      :title="$tc('app.filter', 2)"
      class="filter-client"
      @filter="applyFilter"
      backdrop
    >
      <div class="d-flex flex-column gap-2 pb-2">
        <p>{{ $tc("contacts-component.filter_description") }}</p>
      </div>
      <div class="d-flex flex-column gap-2 pb-2">
        <InputComponent
          :label="$tc('app.name', 1)"
          v-model="filter.name"
          ref="searchInput"
        ></InputComponent>
      </div>
      <div class="d-flex flex-column gap-2 pb-2">
        <FormCheckbox id="checkbox2"  v-model="filter.active">
          Status
        </FormCheckbox>
      </div>
      <template #footer>
        <div class="d-flex justify-content-between">
          <ButtonComponent @click="cleanFilter" variant="secondary"
            >{{ $t("app.clean") }}
          </ButtonComponent>
          <ButtonComponent @click="handleFilterAction"
            >{{ $t("app.apply") }} {{ $tc("app.filter", 2) }}
          </ButtonComponent>
        </div>
      </template>
    </SidebarComponent>
  </div>
</template>

<script>
// @ is an alias to /src
import Swal from 'sweetalert2';
import Dropdown from '@/components/Dropdown.vue';
import DepartmentModal from '@/components/rebranding/departments/DepartmentModal.vue';
import DepartmentService from '@/services/department.service';
import Pagination from '@/components/rebranding/Pagination.vue';
import Modal from '@/mixins/Modal';
import CardComponent from '@/components/CardComponent.vue';
import TableComponent from '@/components/TableComponent.vue';
import SidebarComponent from '@/components/SidebarComponent.vue';
import InputComponent from '@/components/form/InputComponent.vue';
import FormCheckbox from '@/components/rebranding/form/FormCheckbox.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';

export default {
  name: 'DepartmentsCard',
  mixins: [Modal],
  components: {
    TableComponent,
    CardComponent,
    Dropdown,
    DepartmentModal,
    ButtonComponent,
    Pagination,
    SidebarComponent,
    InputComponent,
    FormCheckbox,
  },
  data() {
    return {
      loading: true,
      fields: [
        'name',
        'description',
        {
          key: 'active',
          label: 'status',
        },
        'actions',
      ],
      filter: {
        name: '',
        active: true,
      },
      q: '',
      page: 1,
      pages: 1,
      departments: [],
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    showModalInfo(item) {
      // console.info(item);
      // this.$root.$emit('UsersModal', item);
      this.$refs['department-modal'].showHandler('department-modal', item);
    },
    fetch(page = this.page) {
      this.page = page;
      this.loading = true;
      this.$store.dispatch('fetchDepartments', { page, q: this.q }).then((response) => {
        this.departments = response.data;
        this.pages = response.last_page;
        this.loading = false;
      });
    },
    destroy(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('departments.swal.remove-msg'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
        cancelButtonText: this.$t('app.cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          DepartmentService.removeDepartment(id).then(
            () => {
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('departments.toast.deleted'),
                type: 'success',
              });
              this.loading = false;
              this.fetch();
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.message,
                type: 'danger',
              });
              this.loading = false;
            },
          );
        }
      });
      /* Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('user.destroy'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          this.$store.dispatch('destroyUser', id).then((response) => {
            this.fetch();
          });
        }
      }); */
    },
    changePage(page) {
      /* this.page = page;
      this.$emit('change-page', this.page); */
      this.fetch(page);
    },
    search(q) {
      this.q = q;
      this.fetch(1);
    },
    showFilter() {
      this.$refs.filter.toggle();
    },
    applyFilter() {
      this.fetchDepartments(1);
      this.$refs.filter.toggle();
    },
    fetchDepartments(page = this.currentPage) {
      this.page = page;
      this.loading = true;
      this.$store
        .dispatch('fetchDepartments', { page, ...this.filter })
        .then((response) => {
          this.departments = response.data;
          this.totalPages = response.last_page;
          this.loading = false;
        });
    },
    cleanFilter() {
      this.filter = {
        name: '',
        active: true,
      };
      this.$refs.filter.toggle();
      this.fetch();
    },
    handleFilterAction() {
      this.applyFilter(this.filter);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .pagination_row {
  display: none !important;
}

::v-deep {
  .table-responsive {
    overflow: visible;
  }
}

.filter-department {
  color: #343a40;
}
</style>
